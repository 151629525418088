import CraneImage from '../images/products/crane_overview_1.jpg';
import MobileMixinPlantConcreteImage from '../images/products/mobile_mixin_plant_concrete_overview_1.jpg';
import TelescopicHandlerImage from '../images/products/telescopic_handler_electric_overview_1.jpg';
import SparePartsImage from '../images/filters.jpeg';

const imagesByI18NKey = {
  crane: CraneImage,
  mobileMixinPlantConcrete: MobileMixinPlantConcreteImage,
  telescopicHandler: TelescopicHandlerImage,
  spareParts: SparePartsImage,
};

export const getImageByI18NKey = (key) => {
  return imagesByI18NKey[key] || '';
};
