import { useTranslation } from "react-i18next";
import Typewriter from "typewriter-effect";
import { motion } from "framer-motion";

import Section from "../Section";
import ProductOverview from "../ProductOverview";

const Home = () => {
  const { t } = useTranslation();
  const products = t("products", { returnObjects: true });

  // Variantes para las barras horizontales
  const barVariants = {
    hidden: {
      width: 0,
      opacity: 0,
      y: 20,
    },
    visible: (custom) => ({
      width: `${custom.width}%`,
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    }),
  };

  // Configuración de las barras
  const barConfigurations = [
    { width: 20 }, // Barra 1 izquierda
    { width: 30 }, // Barra 2 izquierda (más grande)
    { width: 25 }, // Barra 3 izquierda
    { width: 20 }, // Barra 1 derecha
    { width: 30 }, // Barra 2 derecha (más grande)
    { width: 25 }, // Barra 3 derecha
  ];

  return (
    <>
      <Section
        id={"home"}
        className="relative flex items-center justify-center text-black overflow-hidden min-h-full"
      >
        {/* Fondo animado con barras horizontales a la derecha e izquierda */}
        <div className="absolute inset-0 flex justify-between items-center px-4 py-8 z-0">
          {/* Barras horizontales a la izquierda */}
          <div className="flex flex-col items-start justify-center space-y-2 h-full w-1/2 -ml-4">
            {barConfigurations.slice(0, 3).map((config, index) => (
              <motion.div
                key={`left-bar-${index}`}
                className="bg-[#0088ff] h-8 rounded-br-xl"
                custom={config}
                variants={barVariants}
                initial="hidden"
                animate="visible"
                style={{ width: `${config.width}%` }}
              />
            ))}
          </div>

          {/* Barras horizontales a la derecha */}
          <div className="flex flex-col items-end justify-center space-y-2 h-full w-1/2 -mr-4">
            {barConfigurations.slice(3).map((config, index) => (
              <motion.div
                key={`right-bar-${index}`}
                className="bg-[#0088ff] h-8 rounded-bl-xl"
                custom={config}
                variants={barVariants}
                initial="hidden"
                animate="visible"
                style={{ width: `${config.width}%` }}
              />
            ))}
          </div>
        </div>

        {/* Contenido: Typewriter y texto */}
        <div className="relative text-center">
          <p className="font-sans font-light text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-black mb-6">
            {t("home.hero.description")}
          </p>
          <Typewriter
            options={{
              strings: [
                t("home.hero.agriculture"),
                t("home.hero.construction"),
                t("home.hero.mining"),
              ],
              autoStart: true,
              loop: true,
              wrapperClassName:
                "font-sans font-light text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-black",
              cursorClassName:
                "font-sans font-light text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-black",
            }}
          />
        </div>
      </Section>
      {/* Sección de productos */}
      {!!products.list &&
        !!products.list.length &&
        products.list.map((product, index, productsArray) => {
          let selectedId = undefined;

          if (index === 0) {
            selectedId = "products";
          } else if (index === productsArray.length - 1) {
            selectedId = "spare-parts";
          }

          return (
            <Section
              className="flex flex-col items-center justify-center pt-28"
              key={product.title}
              id={selectedId}
            >
              <ProductOverview product={product} />
            </Section>
          );
        })}
    </>
  );
};

export default Home;
