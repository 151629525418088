// import { useCallback } from 'react';
// import i18n from 'i18next';
// import { useTranslation } from 'react-i18next';

// import { languages, useI18NContext } from '../../context/i18n';

// const Footer = () => {
//   const { t } = useTranslation();
//   const { setCurrentLanguage } = useI18NContext();

//   const handleChange = useCallback((language: string) => {
//     i18n.changeLanguage(language);
//     setCurrentLanguage(language);
//   }, [setCurrentLanguage]);

//   return (
//     <footer className="grid grid-rows-2 grid-cols-3 h-screen snap-center">
//       <div className="">
//         <p>{t('quickLinks')}</p>
//       </div>
//       <div className="">
//         <p>{t('socialMedia')}</p>
//       </div>
//       <div className="">
//         <p>{t('address')}</p>
//       </div>
//       <div className="">
//         <div onClick={() => { handleChange(languages.ES); }}>{t('spanish')}</div>
//         <div onClick={() => { handleChange(languages.EN); }}>{t('english')}</div>
//       </div>
//     </footer>
//   );
// };

// export default Footer;

import React from "react";
import { Linkedin } from "lucide-react";

import Section from "../Section";
import WhatsappIcon from '../WhatsappIcon';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Section id="contact" withoutPadding className="flex flex-col justify-end items-center pt-28">
      <div className="flex flex-1 flex-col my-16 max-h-48 mx-8">
        <blockquote className="font-sans text-2xl md:text-4xl italic text-center text-gray-800">
          La fuerza detrás de tus grandes obras
        </blockquote>
        <p className="font-sans text-right text-gray-600 text-sm">- SAEMMA</p>
      </div>
      <footer className="flex flex-1 justify-center items-center w-full bg-black text-gray-300 py-24">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          {/* Bloque general para pantallas grandes */}
          <div className="hidden justify-between md:flex">
            <div className="space-y-6 text-left">
              <div className="flex space-x-6">
                <a
                  href="https://www.linkedin.com/company/saemma"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-white"
                >
                  <Linkedin size={32} />
                </a>
                <a
                  href="https://wa.me/+5493512007090"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-white"
                >
                  <WhatsappIcon />
                </a>
              </div>

              {/* <div>
                <a
                  href="#"
                  target="_blank"
                  className="mr-2 font-display text-gray-400 hover:text-white"
                >
                  Privacy Policy
                </a>
                <a
                  href="#"
                  target="_blank"
                  className="mx-2 font-display text-gray-400 hover:text-white"
                >
                  Terms of Use
                </a>
              </div> */}
            </div>

            <div className="space-y-4 text-right">
              <p className="font-display">
                <span className="text-lg font-semibold text-gray-500">
                  Dirección
                </span>
                <br />
                Río Tercero, Córdoba
                <br />
                Argentina
              </p>

              <p className="font-display">
                <span className="mt-4 font-display text-lg font-semibold text-gray-500">
                  Contacto
                </span>
                <br />
                <a
                  href="mailto:contacto@saemma.com"
                  className="font-display text-[#0088ff] hover:underline"
                >
                  contacto@saemma.com
                </a>

                <br />
                <a
                  href="tel:+5493512007090"
                  className="font-display text-[#0088ff] hover:underline"
                >
                  +54 9 (351) 200 7090
                </a>
              </p>
            </div>
          </div>

          {/* Bloque general para pantallas pequeñas */}
          <div className="flex flex-col items-center space-y-6 md:hidden">
            <div className="flex space-x-6">
              <a
                href="https://www.linkedin.com/company/saemma"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white"
              >
                <Linkedin size={32} />
              </a>
              <a
                href="https://wa.me/+5493512007090"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white"
              >
                <WhatsappIcon />
              </a>
            </div>

            <div className="text-center">
              <p className="font-display">
                <span className="text-lg font-semibold text-gray-500">
                  Dirección
                </span>
                <br />
                Río Tercero, Córdoba
                <br />
                Argentina
              </p>

              <p className="mt-4">
                <span className="font-display text-lg font-semibold text-gray-500">
                  Contacto
                </span>
                <br />
                <a
                  href="mailto:contacto@saemma.com"
                  className="font-display text-[#0088ff] hover:underline"
                >
                  contacto@saemma.com
                </a>

                <br />
                <a
                  href="tel:+5493512007090"
                  className="font-display text-[#0088ff] hover:underline"
                >
                  +54 9 (351) 200 7090
                </a>
              </p>
            </div>

            {/* <div className="text-center">
              <a
                href="#"
                target="_blank"
                className="mx-2 font-display text-gray-400 hover:text-white"
              >
                Privacy Policy
              </a>
              <a
                href="#"
                target="_blank"
                className="mx-2 font-display text-gray-400 hover:text-white"
              >
                Terms of Use
              </a>
            </div> */}
          </div>

          <div className="mt-8 text-center">
            <p className="font-display">
              &copy; {currentYear} SAEMMA. Todos los derechos reservados.
            </p>
            <p className="font-display text-sm text-gray-400">
              "Soluciones para tus necesidades"
            </p>
          </div>
        </div>
      </footer>
    </Section>
  );
};

export default Footer;
