const Heading = ({ level, text }) => {
  if (!level || !text) return null;

  if (level === 1) {
    return (
      <h1 className="font-sans font-semibold text-2xl md:text-6xl text-[#26343f] pb-4">
        {text}
      </h1>
    );
  } else if (level === 2) {
    return (
      <h2 className="font-sans uppercase font-semibold text-base text-[#0088ff] pb-4">
        {text}
      </h2>
    );
  }

  return null;
};

export default Heading;
