import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import { ExternalLink, Copy } from "lucide-react";

const ContactButton = ({ href, text, copyText, onCopyText }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(copyText);
    onCopyText();
  };

  return (
    <div className="relative flex h-12 w-full max-w-xs items-center overflow-hidden rounded-lg border-2 border-white text-white bg-[#0088ff]">
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className="flex h-full flex-grow items-center px-4 transition-colors duration-300 hover:underline"
      >
        <ExternalLink size={16} className="mr-2" />
        {text}
      </a>
      <button
        onClick={handleCopy}
        className="h-full px-6 transition-colors duration-300 hover:bg-white hover:text-[#0088ff]"
      >
        <Copy size={16} />
      </button>
    </div>
  );
};

const Tooltip = ({ text }) => (
  <motion.div
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: 10 }}
    className="absolute -top-12 left-1/2 transform -translate-x-1/2 bg-black text-white text-sm py-1 px-3 rounded-lg shadow-lg"
  >
    {text}
  </motion.div>
);

const Menu = ({ isOpenedMenu, handlePressMenu }) => {
  const { t } = useTranslation();
  const navDisplay = isOpenedMenu ? "flex" : "hidden";
  const navClassName = `fixed top-0 right-0 z-10 w-3/4 h-full overflow-auto ${navDisplay} text-white flex flex-col justify-center px-8 bg-[#0088ff] font-sans`;

  const handleLinkClick = useCallback(() => {
    handlePressMenu();
  }, [handlePressMenu]);

  const [showTooltip, setShowTooltip] = useState(false);

  const handleCopyText = () => {
    setShowTooltip(true);
    setTimeout(() => setShowTooltip(false), 2000); // Tooltip disappears after 2 seconds
  };

  const itemVariants = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <motion.nav
      className={navClassName}
      initial={{ opacity: 0, x: "100%" }}
      animate={{ opacity: isOpenedMenu ? 1 : 0, x: isOpenedMenu ? 0 : "100%" }}
      exit={{ opacity: 0, x: "100%" }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex flex-col space-y-8 items-end">
        <motion.a
          href="#home"
          onClick={handleLinkClick}
          className="text-2xl sm:text-3xl lg:text-4xl font-bold hover:underline transition-all duration-300"
          variants={itemVariants}
          initial="hidden"
          animate={isOpenedMenu ? "visible" : "hidden"}
          transition={{ duration: 0.6, delay: 0.1 }}
        >
          {t("home")}
        </motion.a>
        <motion.a
          href="#products"
          onClick={handleLinkClick}
          className="text-2xl sm:text-3xl lg:text-4xl font-bold hover:underline transition-all duration-300"
          variants={itemVariants}
          initial="hidden"
          animate={isOpenedMenu ? "visible" : "hidden"}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          {t("products", { returnObjects: true }).title}
        </motion.a>
        <motion.a
          href="#spare-parts"
          onClick={handleLinkClick}
          className="text-2xl sm:text-3xl lg:text-4xl font-bold hover:underline transition-all duration-300"
          variants={itemVariants}
          initial="hidden"
          animate={isOpenedMenu ? "visible" : "hidden"}
          transition={{ duration: 0.6, delay: 0.3 }}
        >
          {t("spareParts")}
        </motion.a>

        {/* Redesigned contact buttons */}
        <div className="fixed bottom-8 right-8 flex flex-col space-y-4 items-end">
          <motion.div
            variants={itemVariants}
            initial="hidden"
            animate={isOpenedMenu ? "visible" : "hidden"}
            transition={{ duration: 0.6, delay: 0.5 }}
            className="relative"
          >
            <ContactButton
              href="https://wa.me/+5493512007090"
              text="WhatsApp"
              copyText="+5493512007090"
              onCopyText={handleCopyText}
            />
          </motion.div>
          <motion.div
            variants={itemVariants}
            initial="hidden"
            animate={isOpenedMenu ? "visible" : "hidden"}
            transition={{ duration: 0.6, delay: 0.6 }}
            className="relative"
          >
            <ContactButton
              href="mailto:contacto@saemma.com"
              text="Email"
              copyText="contacto@saemma.com"
              onCopyText={handleCopyText}
            />
          </motion.div>

          {/* Mostrar un único tooltip para ambos botones */}
          <AnimatePresence>
            {showTooltip && <Tooltip text="Copiado!" />}
          </AnimatePresence>
        </div>
      </div>
    </motion.nav>
  );
};

export default Menu;
