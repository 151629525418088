import Section from '../Section';

const NoMatch = () => {
  return (
    <>
      <Section>{'section NoMatch'}</Section>
    </>
  );
};

export default NoMatch;
