const Body = ({ children }) => {
  if (!children) return null;

  return (
    <p className="font-sans text-base text-[#26343f]">
      {children}
    </p>
  );
};

export default Body;
