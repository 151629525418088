import { useCallback } from 'react';

const useDisableBodyScroll = () => {
  const disableScroll = useCallback((isModalOpened) => {
    // TODO: improve this code
    // if (isModalOpened) {
    //   // When a modal is shown, we want a fixed body
    //   document.body.style.position = 'fixed';
    //   document.body.style.top = `-${window.scrollY}px`;
    //   document.body.style.paddingRight = '15px';
    // } else {
    //   // When a modal is hidden
    //   const scrollY = document.body.style.top;

    //   document.body.style.position = '';
    //   document.body.style.top = '';

    //   window.scrollTo(0, parseInt(scrollY || '0') * -1);
    // }
    if (isModalOpened) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, []);

  return [disableScroll];
};

export default useDisableBodyScroll;
