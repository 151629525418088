import React, { useState } from "react";

export const languages = {
  EN: "en",
  ES: "es",
};

const i18nContext = React.createContext({
  language: languages.ES,
  setLanguage: () => {},
});

i18nContext.displayName = "i18nContext";

export const useI18NContext = () => React.useContext(i18nContext);

export const I18NProvider = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState(languages.ES);

  const value = {
    currentLanguage,
    setCurrentLanguage
  };

  return (
    <i18nContext.Provider value={value}>
      {children}
    </i18nContext.Provider>
  );
};
