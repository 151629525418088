import React from "react";
import { useTranslation } from "react-i18next";

import colors from '../../utils/colors';

const MenuAction = ({ isOpenedMenu, handlePressMenu }) => {
  const { t } = useTranslation();
  const buttonTextColor = isOpenedMenu ? `text-white` : `text-[${colors.black00}]`;

  return (
    <nav className="flex justify-end items-center">
      <button
        className={`relative h-10 w-10 rounded-sm focus:outline-none ${buttonTextColor}`}
        onClick={handlePressMenu}
      >
        <span className="sr-only">{t(`srOpenMainMenu`)}</span>
        <div className="absolute left-1/2 top-1/2 block w-5 -translate-x-1/2 -translate-y-1/2 transform">
          <span
            aria-hidden="true"
            className={`absolute block h-0.5 w-5 transform bg-current transition duration-500 ease-in-out ${
              isOpenedMenu ? "rotate-45" : "-translate-y-1.5"
            }`}
          ></span>
          <span
            aria-hidden="true"
            className={`absolute block  h-0.5 w-3 transform bg-current transition duration-500 ease-in-out ${
              isOpenedMenu && "opacity-0"
            }`}
          ></span>
          <span
            aria-hidden="true"
            className={`absolute block  h-0.5 w-5 transform bg-current  transition duration-500 ease-in-out ${
              isOpenedMenu ? "-rotate-45" : "translate-y-1.5"
            }`}
          ></span>
        </div>
      </button>
    </nav>
  );
};

export default MenuAction;
