import { useCallback } from 'react';
import { useNavigate } from "react-router-dom";

import SaemmaLogo from '../../images/saemma.png';

const BrandLogo = () => {
  const navigate = useNavigate();
  const handleLogoClick = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <div className="flex items-center cursor-pointer" onClick={handleLogoClick}>
      <img
        src={SaemmaLogo}
        alt="SAEMMA Logo"
        width={200}
      />
    </div>
  );
};

export default BrandLogo;
