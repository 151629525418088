import Heading from "../Heading";
import Body from "../Body";
import { getImageByI18NKey } from "../../utils/images";
import { motion } from "framer-motion";

const ProductOverview = ({ product, className = "" }) => {
  if (!product) return null;

  const { title, subtitle, description, image } = product;
  const { srcKey, alt } = image;

  return (
    <motion.div
      className={`relative ${className}`}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.8, ease: "easeOut" }}
    >
      <div className="flex flex-col md:flex-row items-center md:items-start justify-center gap-8 max-w-6xl mx-auto">
        {/* Imagen */}
        <motion.div
          className="relative flex-shrink-0 w-full md:w-1/2 max-w-lg"
          initial={{ opacity: 0, scale: 0.9 }}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <img
            className="border-8 border-double border-blue-500 object-cover shadow-lg rounded-lg w-full"
            src={getImageByI18NKey(srcKey)}
            alt={alt}
          />
        </motion.div>

        {/* Texto */}
        <motion.div
          className="flex flex-col justify-center w-full md:w-1/2 max-w-lg md:pt-0"
          initial={{ opacity: 0, x: -20 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          {!!title && <Heading level={1} text={title} />}
          {!!subtitle && <Heading level={2} text={subtitle} />}
          {!!description && <Body>{description}</Body>}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default ProductOverview;
