const Section = ({ children, withoutPadding = false, className = "", id = undefined }) => {
  const selectedPadding = withoutPadding ? "px-0" : "px-8"; //h-screen snap-center
  return (
    <section id={id} className={`${selectedPadding} ${className}`}>
      {children}
    </section>
  );
};

export default Section;
