const colors = {
  black00: '#000000',
  blue255: 'rgb(0, 136, 255)',
  blueFF: '#0088ff',
  carbonBlack3F: '#26343f',
  carbonBlack63: 'rgb(38, 52, 63)',
  darkGrey114: 'rgb(92, 104, 114)',
  darkGrey72: '#5c6872',
  lightBlue255: 'rgb(102, 183, 255)',
  lightBlueFF: '#66b7ff',
  lightGray241: 'rgb(192, 219, 241)',
  lightGrayF1: '#c0dbf1',
  mediumGray192: 'rgb(153, 175, 192)',
  mediumGrayC0: '#99afc0',
  yellow0: 'rgb(255, 187, 0)',
  yellow00: '#ffbb00',
};

export default colors;
