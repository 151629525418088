import { useState, useCallback, useEffect } from 'react';

import Menu from '../Menu';
import MenuAction from '../MenuAction';
import BrandLogo from '../BrandLogo';

import useDisableBodyScroll from '../../hooks/use-disable-body-scroll';

const Header = () => {
  const [disableScroll] = useDisableBodyScroll();
  const [isOpenedMenu, setOpenMenu] = useState(false);
  const selectedBackground = isOpenedMenu ? 'bg-transparent' : 'bg-white';

  const handlePressMenu = useCallback(() => {
    setOpenMenu(!isOpenedMenu);
  }, [isOpenedMenu]);

  useEffect(() => {
    disableScroll(isOpenedMenu);
  }, [isOpenedMenu, disableScroll]);

  return (
    <>
      <Menu isOpenedMenu={isOpenedMenu} handlePressMenu={handlePressMenu} />
      <header className={`z-20 grid grid-cols-2 px-8 py-8 w-full fixed ${selectedBackground}`}>
        <BrandLogo />
        <MenuAction isOpenedMenu={isOpenedMenu} handlePressMenu={handlePressMenu} />
      </header>
    </>
  );
};

export default Header;
