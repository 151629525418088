import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from './components/Home';
import Layout from './components/Layout';
import NoMatch from './components/NoMatch';

import './App.css';

const Contact = lazy(() => import('./components/Contact'));
const Products = lazy(() => import('./components/Products'));
const SpareParts = lazy(() => import('./components/SpareParts'));

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route
          path="products"
          element={
            <Suspense fallback={null}>
              <Products />
            </Suspense>
          }
        />
        <Route
          path="spare-parts"
          element={
            <Suspense fallback={null}>
              <SpareParts />
            </Suspense>
          }
        />
        <Route
          path="contact"
          element={
            <Suspense fallback={null}>
              <Contact />
            </Suspense>
          }
        />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}

export default App;
