import { Outlet } from 'react-router-dom';

import Header from '../Header';
import Footer from '../Footer';

const Layout = () => {
  return (
    <>
      <Header></Header>
      <main className='h-screen overflow-y-scroll snap-y snap-mandatory'>
        <Outlet />
        <Footer></Footer>
      </main>
    </>
  );
};

export default Layout;
